.App {
  background-color: #202025;
  color: white;
  min-height: 100vh;
  /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.603); */
}

.html {
  margin: 0;
}

